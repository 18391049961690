<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Transformación - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

           

           

                <b-col md="2">
                  <b-form-group label="Comprobante :">
                    <b-form-select disabled v-model="transformation.type_invoice_output" :options="invoices_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Serie :">
                    <b-form-input disabled type="text" v-model="transformation.serie_output"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Numero :">
                    <b-form-input disabled class="text-right" type="text" v-model="transformation.number_output"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input disabled type="date" v-model="transformation.broadcast_date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Almacen:">
                    <b-form-select disabled v-model="transformation.id_warehouse_output" :options="warehouse"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                   <b-form-group label=".">
                    <b-button disabled class="form-control btn" variant="primary"><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                   </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-input disabled rows="1"  v-model="transformation.observation" max-rows="3"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="w-100 text-center">
                    <h5>PRODUCTOS CON SALIDA A PRODUCCIÓN</h5>
                  </div>
                  <div class="table-responsive mt-3">
                    <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                      <thead >
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="7%" class="text-center">Codigo</th>
                          <th width="75%" class="text-center">Nombre</th>
                          <th width="10%" class="text-center">U.M.</th>
                          <th width="5%" class="text-center">Cantidad</th>
                    

                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in transformations_detail" :key="it">
                        <tr>
                            <td class="align-middle text-center">{{ it + 1 }}</td>
                            <td class="align-middle text-left">{{ item.code }}</td>
                            <td class="align-middle text-left">{{ item.name  }}</td>
                            <td class="align-middle text-center">{{ item.unit_measure }}</td>
                            <td class="align-middle text-right">{{ item.quantity }}</td>
                            <!-- <td class="align-middle text-right">{{ item.capacity }}</td>
                            <td class="align-middle text-right">{{ item.total_capacity }}</td>
                            <td class="align-middle text-right">{{ item.decrease }}</td>
                            <td class="align-middle text-right">{{ item.total_final_capacity }}</td> -->
              
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col md="12">
                  <br>
                </b-col>
                <b-col md="12">
                  <div class="w-100 text-center">
                    <h5>PORCIONES DE PRODUCTOS DERIVADOS</h5>
                  </div>
                  <div class="table-responsive mt-3">
                    <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                      <thead >
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="82%" class="text-center">Nombre</th>
                          <th width="10%" class="text-center">U.M.</th>
                          <th width="5%" class="text-center">Porciones</th>
                          <!-- <th width="5%" class="text-center">Cap.</th>
                          <th width="5%" class="text-center">Cap. Total</th> -->
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in transformations_detail_input" :key="it">
                        <tr>
                            <td class="align-middle text-center">{{ it + 1 }}</td>
                            <td class="align-middle text-left">{{ item.name  }}</td>
                            <td class="align-middle text-center">{{ item.unit_measure }}</td>
                            <td class="align-middle text-right">{{ item.quantity }}</td>
                            <!-- <td class="align-middle text-right">{{ item.capacity }}</td>
                            <td class="align-middle text-right">{{ item.total_capacity }}</td> -->
        
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
          
        

                <b-col md="5"></b-col>
       
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/transformacion/listar' }" append>Regresar</b-link >
                </b-col>

              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->

    <LoadingComponent :is-visible="isLoading"/>
    
  </div>
</template>

<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
// components

import LoadingComponent from './../pages/Loading'

export default {
  name: "ShoppingEdit",
  props: ["id_transformation"],
  components:{
      LoadingComponent,
  },
  data() {
    return {
      isLoading:false,
      module: 'Transformation',
      role: 5,

      transformation: {
        id_transformation:'',
        broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
        observation:'',
        type_operation:'10',
        id_serie_output: '',
        type_invoice_output:'NS',
        serie_output:'',
        number_output:'',
        id_warehouse_output:'',
        state:1,
      },

      

      transformations_detail:[],
      transformations_detail_input:[],
      warehouse:[],

      invoices_type:[
        {value: "09", text : "Guía de remisión - Remitente"},
        {value: "NS", text : "Nota de Salida"},
        {value: "GD", text : "Guía de Despacho"},
      ],
     
    };
  },
  mounted() {

    this.ListWarehouse();
    this.ViewTransformation();
    
  },
  methods: {
    ListWarehouse,
    ViewTransformation,
    NameUnitMeasure,
    
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};
function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function ListWarehouse() {
  let me = this;
  let url = this.url_base + "warehouse/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      me.warehouse.push({value: '', text: '-- Seleccione un almacen --'});
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.warehouse.push({value: element.id_warehouse, text: element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function ViewTransformation() {

  let id_transformation = je.decrypt(this.id_transformation);
  let me = this;
  let url = this.url_base + "transformation/view/" + id_transformation;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {

    
          me.transformation.id_transformation = response.data.result.transformation.id_transformation;
          me.transformation.broadcast_date = response.data.result.transformation.broadcast_date;
          me.transformation.type_operation = response.data.result.transformation.type_operation;
          me.transformation.observation = response.data.result.transformation.observation;
          me.transformation.id_serie_output = response.data.result.transformation.id_serie_output;
          me.transformation.type_invoice_output = response.data.result.transformation.type_invoice_output;
          me.transformation.serie_output = response.data.result.transformation.serie_output;
          me.transformation.number_output = response.data.result.transformation.number_output;
          me.transformation.id_warehouse_output = response.data.result.transformation.id_warehouse_output;
          me.transformation.state = response.data.result.transformation.state;
    

          me.transformations_detail = response.data.result.transformations_detail;
          me.transformations_detail_input = response.data.result.transformations_detail_input;
          
      } else {
        Swal.fire("Sistema", "A Ocurrido un error", "error");
      }

      me.isLoading = false;
    })
}



</script>
